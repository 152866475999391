import axios from "axios";

export default {
  data() {
    return {
      agents: "",
      todaysTransactions: [],
      loading: false,
      searchQuery: "",
      selectedValues: [],
      individualOrGroup: [],
      paymentType: [],
    };
  },
  methods: {
    async getAgents() {
      this.loading = true;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/admin/users/list-agents?page=1&limit=50&sortBy`
        );
        this.agents = response.data.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getTodaysTransactions() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/admin-transaction/today`
        );
        this.todaysTransactions = response.data.reverse();
      } catch (error) {
        console.log(error);
      }
    },
    // Function to format timestamp to time
    formatTime(timestamp) {
      const date = new Date(timestamp);
      const hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const period = hours >= 12 ? "PM" : "AM";
      const displayHours = hours % 12 || 12; // Convert to 12-hour format
      return `${displayHours}:${minutes} ${period}`;
    },
  },
  computed: {
    calculateTransactions() {
      return this.todaysTransactions
        .filter(
          (transaction) =>
            transaction.transactionType !== "Withdrawal" &&
            transaction.transactionType !== "SavingsDeposit"
        )
        .reduce((accumulator, transaction) => {
          // Check if transactionData and changes exist before accessing transactionAmount
          if (
            transaction.transactionData &&
            transaction.transactionData.changes &&
            transaction.transactionData.changes.transactionAmount !== undefined
          ) {
            // Parse the transactionAmount to ensure it's a valid number
            const transactionAmount = parseFloat(
              transaction.transactionData.changes.transactionAmount
            );

            // Check if the parsed transactionAmount is a valid number
            if (!isNaN(transactionAmount)) {
              return accumulator + transactionAmount;
            }
          }

          // If any of the checks fail, return the accumulator unchanged
          return accumulator;
        }, 0.0);
    },
    momoTransactions() {
      return this.todaysTransactions
        .filter((transaction) => {
          return (
            transaction.transactionType !== "Withdrawal" &&
            transaction.transactionData?.changes?.paymentTypeId === "2"
          );
        })
        .reduce((accumulator, transaction) => {
          return (
            accumulator +
            parseFloat(transaction.transactionData.changes.transactionAmount)
          );
        }, 0.0);
    },
    cashTransactions() {
      return this.todaysTransactions
        .filter((transaction) => {
          return (
            transaction.transactionType !== "Withdrawal" &&
            transaction.transactionType !== "SavingsDeposit" &&
            transaction.transactionData?.changes?.paymentTypeId === "1"
          );
        })
        .reduce((accumulator, transaction) => {
          return (
            accumulator +
            parseFloat(transaction.transactionData.changes.transactionAmount)
          );
        }, 0.0);
    },
    cashWithdrawals() {
      return this.todaysTransactions
        .filter((transaction) => {
          return (
            transaction.transactionType === "Withdrawal" &&
            transaction.transactionData?.changes?.paymentTypeId === "1"
          );
        })
        .reduce((accumulator, transaction) => {
          return (
            accumulator +
            parseFloat(transaction.transactionData.changes.transactionAmount)
          );
        }, 0.0);
    },
    cashCollaterals() {
      return this.todaysTransactions
        .filter((transaction) => {
          return (
            transaction.transactionType === "SavingsDeposit" &&
            transaction.transactionData?.changes?.paymentTypeId === "1"
          );
        })
        .reduce((accumulator, transaction) => {
          return (
            accumulator +
            parseFloat(transaction.transactionData.changes.transactionAmount)
          );
        }, 0.0);
    },
    filterCustomer() {
      let filteredTransactions = [...this.todaysTransactions]; // Create a copy of transactions

      if (this.searchQuery !== "") {
        const searchQueryLowerCase = this.searchQuery.toLowerCase();
        filteredTransactions = filteredTransactions.filter((transaction) =>
          transaction?.customerName === undefined
            ? false
            : transaction.customerName
                .toLowerCase()
                .includes(searchQueryLowerCase)
        );
      }

      if (this.individualOrGroup.length > 0) {
        filteredTransactions = filteredTransactions.filter((transaction) =>
          this.individualOrGroup.includes(transaction.transactionPaymentType)
        );
      }

      if (this.selectedValues.length > 0) {
        filteredTransactions = filteredTransactions.filter((transaction) =>
          this.selectedValues.includes(transaction.transactionType)
        );
      }

      if (this.paymentType.length > 0) {
        filteredTransactions = filteredTransactions.filter((transaction) =>
          transaction?.transactionData?.changes?.paymentTypeId === undefined
            ? false
            : this.paymentType.includes(
                transaction.transactionData.changes.paymentTypeId
              )
        );
      }

      return filteredTransactions;
    },

    agentSummed() {
      const summedAmounts = this.todaysTransactions.reduce((acc, item) => {
        const agentName = item.agentName;
        const agentId =
          typeof item.agent === "string" ? item.agent : item.agent?._id;

        // Skip if either agentName or agentId is missing
        if (!agentName || !agentId) {
          return acc;
        }

        // Extract transaction amount safely
        const transactionAmount = parseFloat(
          item.transactionData?.changes?.transactionAmount || 0
        );

        // If the agent already exists in the accumulator, add the transaction amount
        if (acc[agentId]) {
          acc[agentId].totalAmount += transactionAmount;
        } else {
          // Otherwise, create a new entry for the agent
          acc[agentId] = {
            agentName: agentName,
            totalAmount: transactionAmount,
            agentId: agentId,
          };
        }

        return acc;
      }, {});

      // Convert the accumulated result to an array of objects
      return Object.keys(summedAmounts).map((agentId) => {
        return {
          agentId: summedAmounts[agentId].agentId,
          agentName: summedAmounts[agentId].agentName,
          totalAmount: summedAmounts[agentId].totalAmount.toLocaleString(), // Format as a string
        };
      });
    },
  },
};
